import { useCallback, useEffect, useRef, useState } from "react"
import { server } from "../../../server"
import type { SendMail } from "./SendMail"
import { EmailTemplate } from "../../../packages/email/EmailTemplate"
import { useBoundState } from "../../../packages/editing/useBoundState"
import { ColorStyles } from "../../../packages/ui"
import { Editors } from "../../Views/Editors"
import { RButton } from "../../Views/Buttons"

/**
 * This is the Studio SPA-side editor for the SendMail HTML body.
 *
 * This launcehs an iframe with the email template SPA, and communicates with it
 * by posting messages.
 */
Editors.push(SendMailBodyEditor)
function SendMailBodyEditor(props: { obj: SendMail }) {
    const template = EmailTemplate.templates.find((t) => t.name === props.obj.template)

    const [sections, setSections, setSectionsLocally] = useBoundState(props.obj, "sections")

    const staticUrl = `${server()}/static/${template?.htmlFileName}`
    const hotReloadUrl = `http://localhost:3001/${template?.htmlFileName}`

    const [childOrigin, setChildOrigin] = useState(staticUrl)

    // When clicking Ctrl+H, toggle between hot reload and static URL
    useEffect(() => {
        const handler = (event: KeyboardEvent) => {
            if (event.ctrlKey && event.key === "h") {
                setChildOrigin((prev) => (prev === hotReloadUrl ? staticUrl : hotReloadUrl))
            }
        }

        window.addEventListener("keydown", handler)

        return () => {
            window.removeEventListener("keydown", handler)
        }
    }, [hotReloadUrl, staticUrl])

    const iframeRef = useRef<HTMLIFrameElement>(null)
    const [error, setError] = useState<string | undefined>(undefined)

    const updateEditor = useCallback(async () => {
        try {
            // Send a message back to the child iframe
            iframeRef.current?.contentWindow?.postMessage(
                { update: { sections: props.obj.sections } },
                childOrigin
            )
            setError(undefined)
        } catch (e: any) {
            setError(e.detail ?? e.message)
        }
    }, [iframeRef, props.obj.sections, childOrigin])

    useEffect(() => {
        const handler = async (event: MessageEvent<any>) => {
            if (childOrigin.startsWith(event.origin)) {
                if (event.data.status === "ready") await updateEditor()
                if (event.data.update) {
                    if ("sections" in event.data.update) {
                        setSections(event.data.update.sections)
                    }
                }
            }
        }

        // Listen for messages from the iframe
        window.addEventListener("message", handler)

        return () => {
            window.removeEventListener("message", handler)
            // We do NOT want to remove the debounceTimeoutRef.current here,
            // as it would cause the debounce to be reset every time this hook
            // is called, which may lose some updates.
        }
    }, [updateEditor, childOrigin, sections, setSections, setSectionsLocally])

    useEffect(() => {
        if (!template) {
            // If there is no template, we won't load the iframe and will just
            // display the markdown. We still need to evaluate the code.
            void updateEditor()
        }
    }, [template, updateEditor, sections])

    if (error) {
        return (
            <div
                style={{
                    padding: 16,
                    backgroundColor: ColorStyles.warning[100],
                    border: `1px solid ${ColorStyles.warning[400]}`,
                    color: ColorStyles.warning[700],
                    borderRadius: 4,
                }}
            >
                Unable to preview email template: {error}
            </div>
        )
    }

    return (
        <div style={{ position: "relative" }}>
            {server().includes("localhost") && (
                <button
                    style={{
                        padding: 4,
                        borderRadius: 4,
                        backgroundColor: "lightblue",
                        position: "absolute",
                        top: 6,
                        right: 6,
                        fontSize: 8,
                    }}
                    onClick={() =>
                        setChildOrigin((prev) => (prev === hotReloadUrl ? staticUrl : hotReloadUrl))
                    }
                >
                    {childOrigin === hotReloadUrl ? "Static mode" : "Hot reload mode"}
                </button>
            )}
            {sections ? (
                <iframe
                    key={childOrigin}
                    ref={iframeRef}
                    src={childOrigin}
                    width="100%"
                    height="1024"
                    style={{ border: "none" }}
                />
            ) : (
                <RButton variant="secondary" onClick={() => setSections([])}>
                    Create
                </RButton>
            )}
        </div>
    )
}
